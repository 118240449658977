import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ImageCropperModule } from 'ngx-image-cropper';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule }      from '@angular/forms';

import { SubirFotoComponent } from './componentes/subir-foto/subir-foto.component';
import { InicioComponent } from './pages/inicio/inicio.component';
import { SanitizarPipe } from './pipe/sanitizar.pipe';
import { ListarComponent } from './componentes/listar/listar.component';
import { TarjetasComponent } from './componentes/tarjetas/tarjetas.component';

@NgModule({
  declarations: [
    AppComponent,
    SubirFotoComponent,
    InicioComponent,
    SanitizarPipe,
    ListarComponent,
    TarjetasComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    ImageCropperModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
