import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InicioComponent } from './pages/inicio/inicio.component';
import { TarjetasComponent } from './componentes/tarjetas/tarjetas.component';
import { ListarComponent } from './componentes/listar/listar.component';

const routes: Routes = [
                        {path: 'inicio', component: InicioComponent },
                        {path: 'listar', component: ListarComponent },
                        {path: '', pathMatch: 'full', redirectTo: 'inicio' },
                        {path: '**', pathMatch: 'full', redirectTo: 'inicio '}
                      ];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
