<div (contextmenu)="onRightClick($event)">
    <h3>{{ idUsuario }} {{ nombre }}</h3>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <a class="navbar-brand" href="#">Seleccionar Producto</a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">

            </ul>
            <div class="form-inline my-2 my-lg-0">
                <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" [(ngModel)]="buscar">
                <button class="btn btn-outline-success my-2 my-sm-0" type="submit" (click)="inputBuscar()">Buscar</button>
                <button class="btn btn-outline-success my-2 my-sm-0 ml-2" type="submit" (click)="visible(subir)">Agregar</button>
            </div>
        </div>
    </nav>

    <div class="container ">
        <div class="row">
            <div class="col-md-2 mt-1">
                <div class="card">
                    <img class="card-img-top" src="{{imagen}}" alt="{{nombre}}">
                    <div class="card-body">
                        <h5 class="card-title">{{nombre}}</h5>
                        <p class="card-text">{{codigo}}</p>
                    </div>
                    <div class="card-body">
                        <!-- <a href="#" class="card-link">Seleccionar Archivo</a> -->
                    </div>
                </div>
            </div>

            <div class="col-md-2 mt-1" *ngFor="let item of datos.data; let i = index">
                <div class="card">
                    <img class="card-img-top" src="http://imgixeloa6vlvsc.supercajero.com/{{item.nombre_archivo}}" alt="{{item.nombre_producto}}">
                    <div class="card-body">
                        <h5 class="card-title">{{item.nombre_producto}}</h5>
                        <p class="card-text">{{item.codigo_barra}}</p>
                    </div>

                    <div class="card-body">
                        <button class="card-link" (click)="callSeleccionar(item.id, codigo)">Seleccionar Archivo</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #subir let-modal>
    <app-subir-foto> </app-subir-foto>
</ng-template>
