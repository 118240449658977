import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ImagenService {

  constructor( private http: HttpClient) {
    console.log('servicio listo');
  }

  // public url  = `http://www.koma.cl/prueba/servicio.php`;
  // public url  = `http://www.koma.cl/prueba/reportegeneral.php`;
  // public url  = 'http://192.168.1.15:8000/api';
  // public url = 'http:/localhost:8000/api';
   // public url = 'http://127.0.0.1:8000/api';
   // public url = '/api';
    public url = 'http://imgapi.supercajero.com/api';

  public urljson = '';


  public httpOptions = {
    headers: new HttpHeaders( { 'Content-Type': 'application/json' }  )
   //
  };


  getDato(body: any) {
      console.log('INICIO DE GETDATO' + JSON.stringify(body));
      const ladata =  this.http.post(this.urljson, JSON.stringify(body), this.httpOptions);
      return ladata;
  }

}
