import { Component, OnInit, Input } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ImagenService } from '../../service/imagen.service';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-subir-foto',
  templateUrl: './subir-foto.component.html',
  styleUrls: ['./subir-foto.component.css']
})
export class SubirFotoComponent implements OnInit {

  datos: imagen;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  preview: any;
  modalReference: any;

  constructor(private sps: ImagenService, private modalService: NgbModal, private rutaActiva: ActivatedRoute) { }

  ngOnInit(): void {

//     alert(params.get('var'));



     this.datos = new imagen;
     this.datos.id_usuario = 1;  ///this.rutaActiva.snapshot.params.usuario;
     this.datos.estado = true;
  }

  callAPi() {
    console.log(this.datos);
    this.sps.urljson = this.sps.url + '/imagen';
    this.sps.getDato( this.datos ).subscribe(
      (data: any) => {
          console.log(data);
          this.modalService.dismissAll();
      });
    }

  addPictures() {
   /// this.callAPi();
    //this.modalService.dismissAll();
   this.modalReference.close();
  }

  fileChangeEvent(event: any, content): void {
    this.imageChangedEvent = event;
    this.visible(content);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.datos.imgBase64 = this.croppedImage;
  }

  visible(content) {
   this.modalReference =  this.modalService.open(content, { size: 'lg'});
  }

}


class imagen {
    codigo_barra: string ;
    nombre_producto: string;
    keywords: string;
    publico: boolean;
    estado: boolean;
    id_usuario: number;
    imgBase64: string;
  }
