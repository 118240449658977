import { Component, OnInit, AfterViewInit  } from '@angular/core';
import { ImagenService } from '../../service/imagen.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-listar',
  templateUrl: './listar.component.html',
  styleUrls: ['./listar.component.css']
})
export class ListarComponent implements OnInit{

  public jsonApi: any;
  public urlApi: any;
  public jsonSeleccionar: Seleccion;
  public datos: DatosClass;
  idUsuario: number;
  nombre: string;
  codigo: string;
  buscar: string;
  imagen: any;

  constructor(private sps: ImagenService,  private modalService: NgbModal) { }

    ngOnInit(): void {
      //  this.urlApi =  'http://127.0.0.1:8000/api/img/';
        this.urlApi =  'http://imgapi.supercajero.com/api';
        const querystring = window.location.search;
        const params = new URLSearchParams(querystring);

        this.idUsuario = 1;
        if (params.get('id') != null) {
           this.idUsuario = parseInt( params.get('id') , 0 );
        }


        this.nombre ="";
        if(params.get('nombre') != null) {
        this.nombre = params.get('nombre');
        }

        this.codigo ="";
        if(params.get('codigo') != null) {
        this.codigo = params.get('codigo');
        }

        this.buscar = "";

        this.datos = new DatosClass;
        this.jsonApi =  { 'headers': { 'page': 1 }, 'sorts': [{ 'prop' : 'id', 'dir' : 'asc' }]  };
        this.jsonApi.id_usuario = this.idUsuario;
        this.callAPI();

        this.imagen = this.urlApi + '/img/' +  this.codigo + '/' + this.nombre + '/' + this.idUsuario;

        }


    inputBuscar() {
        const filtering = new Array();

        const find = new Cfilter();
        find.prop = 'nombre_producto';
        find.filter = this.buscar;
        find.type = 'AND';
        filtering.push(find);

        const find2 = new Cfilter();
        find2.prop = 'keywords';
        find2.filter = this.buscar;
        find2.type = 'OR';
        filtering.push(find2);


        this.jsonApi.filtering = filtering;
        this.jsonApi.headers.page = 1;


        this.callAPI();

      }

    callAPI(): void {
          console.log(this.datos);
          console.log(this.jsonApi);

          this.sps.urljson = this.sps.url + '/img/listar';
          this.sps.getDato( this.jsonApi ).subscribe(
          (data: any) => {

               console.log(data);
               this.datos = data;
           });
       }


   callSeleccionar(idImagen, codigoBarra): void {
        this.imagen = 'http://imgixeloa6vlvsc.supercajero.com/comodin.jpg';
        this.jsonSeleccionar = new Seleccion();
        this.jsonSeleccionar.idImagenes = idImagen;
        this.jsonSeleccionar.idUsuario = this.idUsuario;
        this.jsonSeleccionar.codigoBarra = codigoBarra;

        this.sps.urljson = this.sps.url + '/img/seleccionar';
        this.sps.getDato( this.jsonSeleccionar ).subscribe(
        (data: any) => {
             console.log(data);
             //this.datos = data;
            //  this.callAPI();
             this.imagen = 'http://imgixeloa6vlvsc.supercajero.com/' + data.nombre_archivo;

         });

   }

   visible(content) {
        this.modalService.open(content, { size: 'lg'});
   }

   onRightClick(event) {
    event.preventDefault();
  }

}

class Cfilter {
  prop: string;
  filter: string;
  type: string;
}

class Seleccion {
  idImagenes: number;
  idUsuario: number;
  codigoBarra: string;
}

class DatosClass {
  data: any;
  header: any;
  sorts: any;

}
