<div class="container">

    <!-- form user info -->
    <div class="card card-outline-secondary">
        <div class="card-header">
            <h3 class="mb-0">Subir Foto </h3>
        </div>
        <div class="card-body">


            <div class="row">
                <div class="col-lg-7">

                    <div class="form-group row">
                        <label class="col-lg-3 col-form-label form-control-label">Nombre Producto</label>
                        <div class="col-lg-9">
                            <input class="form-control" type="text" [(ngModel)]="datos.nombre_producto">
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-lg-3 col-form-label form-control-label">Codigo de Barra</label>
                        <div class="col-lg-9">
                            <input class="form-control" type="text" [(ngModel)]="datos.codigo_barra">
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-lg-3 col-form-label form-control-label">keywords</label>
                        <div class="col-lg-9">
                            <input class="form-control" type="text" [(ngModel)]="datos.keywords">
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-lg-3 col-form-label form-control-label">Permiso Imagen</label>
                        <div class="col-lg-9">
                            <select id="publico" name="publico" class="form-control" size="0" [(ngModel)]="datos.publico">
                                  <option value="1">Publico</option>
                                 <option value="0">Privado</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-lg-3 col-form-label form-control-label">Seleccionar Foto:</label>
                        <div class="col-lg-4">
                            <div class="input-group">
                                <div class="custom-file">
                                    <input type="file" class="foto" (change)="fileChangeEvent($event, contents)" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <button class="btn-primary btn-lg float-right" (click)="callAPi()"> Guardar </button>
                    </div>
                </div>

                <div class="col-lg-5">
                    <img src="{{ croppedImage }}" class="img-fluid" alt="{{datos.nombre_producto}}" />
                </div>

            </div>
        </div>
    </div>
    <!-- /form user info -->


</div>

<ng-template #contents let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Subir Imagen</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
    </div>
    <div class="modal-body">
        <div>
            <div class="row">
                <div class="form-group col-6">
                    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="4 / 4" [resizeToWidth]="400" format="jpg" (imageCropped)="imageCropped($event)"></image-cropper>

                </div>

                <div class="form-group col-6 ">
                    <img [src]="croppedImage" width="250px" />
                </div>
            </div>
            <div class="modal-footer ">
                <button class="butoncus_sig" id="dnext" (click)="addPictures()">Guardar</button>
            </div>
        </div>
    </div>
</ng-template>
